<template>
  <power-bi-report :report-id="reportId" />
</template>

<script>
export default {
  components: {
    PowerBiReport: () => import('@/modules/external_bi/components/PowerBiReport')
  },
  computed: {
    reportId() {
      return '8c704cd6-14cc-41f2-bdbf-40ac497163f5'
    }
  }
}
</script>
